export default {
	levels: ['低', '中', '高'],
	colors: ['#e53935', '#ffac13', '#33CC00'],
	securityTips: ['请设置密码', '请绑定邮箱以进一步降低风险', '太棒了，风险已降至最低'],
	buttons: [{
			iconUrl: 'el-icon-s-order',
			title: '待兑换的订单',
			tip: '查看待兑换订单',
			linkUrl: '/order/index?status=1',
			key:'WaitPayment'
		},
		{
			iconUrl: 'el-icon-edit-outline',
			title: '待收货的订单',
			tip: '查看待收货订单',
			linkUrl: '/order/index?status=2',
			key:'WaitReceiving'
		},
		// {
		// 	iconUrl: 'el-icon-edit',
		// 	title: '待评价商品数',
		// 	tip: '查看待评价商品',
		// 	linkUrl: '/pages/order/comment/index',
		// 	key:'WaitEvaluate'
		// },
		// {
		// 	iconUrl: 'el-icon-star-on',
		// 	title: '喜欢的商品数',
		// 	tip: '查看喜欢的商品',
		// 	linkUrl: '/pages/user/favorite/index',
		// 	key:'CollectionNumber'
		// }
	]
}
