<template>
  <div v-if="accountInfo">
    <!-- 面包屑 -->
    <!-- <bread-crumb pages="个人中心" separator="/"></bread-crumb> -->

    <!-- 左侧导航栏 -->
    <menu-wrapper active-index="" v-loading="loading">
      <div class="card-header">
        <div class="left">
          <!-- <img class="avatar" :src='loginUser.avatarUrl || "./assets/imgs/userHead.jpg" '/> -->
          <el-avatar
            :size="150"
            :src="require('@/assets/imgs/userHead.png')"
            @error="errorHandler"
          >
            <img :src="loginUser.avatarUrl" />
          </el-avatar>
          <div class="user-info">
            <div class="user-name elip">{{ accountInfo.UserName }}</div>
            <div class="tip">{{ currentTime }}好~</div>
            <router-link class="link" to="/user/info"
              >修改个人信息></router-link
            >
          </div>
        </div>
        <div class="right">
          <div class="item">
            <div class="name">账户云淘豆：</div>
            <div class="value balance">
              <router-link class="link balance" to="/user/balancehistory" title="查看流水记录"><span>{{ $util.numberFormat(accountInfo.Balance)  }}</span></router-link>
            </div>
          </div>
          <div class="item">
            <div class="name">绑定手机：</div>
            <div class="value">{{ accountInfo.PhoneNumber }}</div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="button" v-for="(item, index) in buttons" :key="index">
          <div class="btn-icon">
            <i :class="item.iconUrl"> </i>
          </div>
          <div class="right">
            <div class="top">
              <div class="name">{{ item.title }}：</div>
              <div class="value">{{ accountInfo[item.key] }}</div>
            </div>
            <router-link class="link" :to="item.linkUrl">
              <div class="tip">{{ item.tip }}</div>
              <i class="icon i-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </menu-wrapper>

    <!-- 页脚 -->
  </div>
</template>

<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";
import centerConfig from "@/common/config/user/center.config.js";

export default {
  components: {
    menuWrapper,
  },
  data() {
    return {
      ...centerConfig,
      accountInfo: {},
      counts: [1, 2, 3, 4],
      loginUser: {
        avatarUrl: "",
        name: "ss",
      },
      loading: true,
    };
  },
  computed: {
    currentTime() {
      const currentHour = new Date().getHours();
      const timeQuantums = ["凌晨", "上午", "下午", "晚上"];
      return timeQuantums[Math.floor(currentHour / 6)];
    },
  },
  activated() {
    this.getAccountInfo();
  },
  methods: {
    getAccountInfo() {
      this.loading = true;
      this.$axios
        .get("/api/User/GetPersonalCenter", "")
        .then((res) => {
          this.accountInfo = res.data;
          this.loading = false;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    errorHandler() {
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  padding-top: 24px;
  @extend %flex-align-center;

  .left {
    @extend %flex-align-center;
    .avatar {
      width: 150px;
      height: 150px;
      padding: 4px;
      border: 1px solid $border-color;
      border-radius: 50%;
      object-fit: fill;
    }

    .user-info {
      width: 302px;
      margin-left: 20px;

      .user-name {
        max-width: 220px;
        font-size: 24px;
        font-weight: 400;
        color: #616161;
      }

      .tip {
        margin-top: 5px;
        color: #b0b0b0;
      }

      .link {
        display: inline-block;
        font-size: 12px;
        margin-top: 10px;
        color: $color-primary;
      }
    }
  }

  .right {
    .item {
      @extend %flex-align-center;
      padding: 6px 0;
      color: #757575;
      .balance {
        color: #ff6700;
        font-size: 24px;
      }
    }
  }
}

.card-body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  padding-top: 50px;
  border-top: 1px solid $border-color;

  .button {
    margin-bottom: 60px;
    @extend %flex-align-center;

    .btn-icon {
      // width: 100px;
      // height: 100px;
      // border-radius: 50%;
      font-size: 50px;
    }

    .right {
      width: 276px;
      margin-left: 20px;

      .top {
        @extend %flex-align-center;
        font-size: 18px;
        font-weight: 400;

        .name {
          color: #757575;
        }

        .value {
          color: $color-primary;
        }
      }

      .link {
        line-height: 1.8;
        @extend %flex-align-center;
        color: #757575;
        cursor: pointer;
        
        .balance{
          color: #ff6700;
        }

        &:hover {
          color: $color-primary;
        }

        .icon {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
